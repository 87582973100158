<script setup>
import { ref } from 'vue'
import DashboardDataService from '@/services/DashboardDataService.js'

const wasEmailSent = ref(false)

function resendEmailVerification () {
  return DashboardDataService.resendActivationLink().then(() => {
    wasEmailSent.value = false
  })
}
</script>

<template>
  <div class="wrapper">
    <p class="main-copy mb-0">
      You need to confirm your account before you can insure parcels
    </p>
    <span v-if="wasEmailSent">
      We’ve sent you a confirmation email. If you need us to resend it,
      <span @click="resendEmailVerification">
        please click here.
      </span>
    </span>
    <span v-else>
      We've sent you a new confirmation email.
    </span>
  </div>
</template>

<style scoped>
.wrapper {
  margin: 40px 0;
}
p.main-copy {
  font-size: 14px;
  text-decoration: underline;
  font-weight: bold;
}
span {
  font-size: 14px;
}
</style>

<template>
  <div
    class="modal fade onBoarding show"
    id="welcomeModalStep1"
    tabindex="-1"
    data-backdrop="static"
    data-keyboard="false"
    role="dialog"
    aria-labelledby="welcomeModalStep1Title"
    style="padding-right: 15px; display: block; z-index: 10000"
    aria-modal="true"
  >
    <div
      id="modal-body"
      class="modal-dialog w-90 modal-dialog-centered"
      role="document"
    >
      <div class="modal-content h-100">
        <div class="modal-header">
          <h2
            class="col-12 modal-title onboarding-modal-title text-center"
            id="welcomeModalStep0Title"
            data-test="onboarding-step-2-title"
          >
            STEP {{ step }}: Select Insurance Coverage Values
          </h2>
        </div>
        <div class="loading_bar w-50">&nbsp;</div>
        <div
          class="modal-body px-0 py-0 h-100"
          style="height: 75vh !important; display: flex; align-items: center"
        >
          <div class="container" style="max-width: none !important">
            <div class="container h-100 my-5">
              <p class="mb-0 font-weight-bold"></p>
              <h5 class="mb-9 text-center">
                Select the retail values of the parcels you would like to
                insure.
              </h5>
              <div class="row" style="margin-left: 0px">
                <InsuranceLimitsSlider
                  :min_slider_value="this.min_insurance_value"
                  :max_slider_value="this.max_insurance_value"
                  :maximum_min_value="50"
                  @changedValues="onCoverageRangesChange"
                ></InsuranceLimitsSlider>
              </div>
              <p class="my-5 text-left">
                You have selected to insure all parcels with a retail value of
                between <b data-test="onboarding-min-insured-parcels-text">£{{ this.min_insurance_value }}.00</b> and
                <b data-test="onboarding-max-insured-parcels-text">£{{ this.max_insurance_value }}.00</b>.
              </p>
              <p class="my-5 text-left">
                All parcels within the values you have selected will
                automatically be insured. You can change the values of parcels
                to insure at any time in the app.
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer p-4">
          <button
            id="welcomeModalStep2Back"
            @click="$emit('prevScreen')"
            class="btn btn-link float-right"
            data-test="onboarding-step-2-back"
          >
            Go Back
            <i class="fas fa-chevron-right text-white" aria-hidden="true"></i>
          </button>
          <div class="btn-group">
            <button
              id="welcomeModalStep0Next"
              @click="setOrderValueRange"
              type="submit"
              class="btn btn-secondary-purple float-right"
              data-test="onboarding-step-2-next"
            >
              Proceed to Business Info
              <i class="fas fa-chevron-right text-white" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InsuranceLimitsSlider from '@/components/InsuranceLimitsSlider.vue'
import OnBoardingDataService from '@/services/OnBoardingDataService.js'

export default {
  name: 'Step2InsuranceRange',
  props: {
    step: String,
    min_insurance_value_prop: Number,
    max_insurance_value_prop: Number
  },
  data () {
    return {
      min_insurance_value: this.min_insurance_value_prop,
      max_insurance_value: this.max_insurance_value_prop
    }
  },
  components: {
    InsuranceLimitsSlider
  },
  methods: {
    onCoverageRangesChange (minValue, maxValue) {
      this.min_insurance_value = minValue
      this.max_insurance_value = maxValue
    },

    setOrderValueRange () {
      return OnBoardingDataService.setOrderValueRange(
        this.min_insurance_value,
        this.max_insurance_value
      ).then((response) => {
        this.$emit('onChangeInsuranceValues', this.min_insurance_value, this.max_insurance_value)
        this.$emit('getPolicyholderData')
        this.$emit('nextScreen')
      })
    }
  }
}
</script>

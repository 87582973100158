<script setup>
import EmailVerification from './EmailVerification.vue'
import PolicyHolderID from './PolicyHolderID.vue'

const props = defineProps(
  {
    policyholder: {
      type: Object
    }
  }
)

const { policyholder } = props
</script>

<template>
  <EmailVerification />
  <PolicyHolderID :policyholder="policyholder" />
</template>

<template>
  <div
    class="modal fade onBoarding show"
    id="welcomeModalStep3"
    tabindex="-1"
    data-backdrop="static"
    data-keyboard="false"
    role="dialog"
    aria-labelledby="welcomeModalStep3Title"
    style="padding-right: 15px; display: block; z-index: 10000"
    aria-modal="true"
  >
    <div
      id="modal-body"
      class="modal-dialog w-90 modal-dialog-centered"
      role="document"
    >
      <div class="modal-content h-100">
        <div class="modal-header">
          <h2
            class="col-12 modal-title onboarding-modal-title text-center"
            id="welcomeModalStep0Title"
            data-test="onboarding-step-3-title"
          >
            STEP {{ step }}: Provide your business details
          </h2>
        </div>
        <div :class="loadingBarClass">&nbsp;</div>
        <div class="modal-body px-0 py-0 h-100" ref="welcomeModalStep3Body">
          <div class="container" style="max-width: none !important">
            <div class="row justify-center">
              <div class="container h-100">
                <div class="justify-content-center align-self-center my-5">
                  <div
                    class="alert alert-danger"
                    role="alert"
                    v-if="invalidCompany"
                  >
                    <p>
                      {{ invalidCompanyMessage }}
                    </p>
                  </div>
                  <CompanyInfo
                    ref="companyInfo"
                    :SaveButton="false"
                    :SourcePartner="this.policyholder.source_partner"
                    :companyInfoData="this.companyInfoData"
                    @savedCompanyInfo="(companyInfoData) => $emit('onChangeCompany', companyInfoData)"
                  ></CompanyInfo>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer p-4">
          <button
            id="welcomeModalStep2Back"
            @click="$emit('prevScreen')"
            class="btn btn-link float-right"
            data-test="onboarding-step-3-back"
          >
            Go Back
            <i class="fas fa-chevron-right text-white" aria-hidden="true"></i>
          </button>
          <div class="btn-group">
            <button
              id="welcomeModalStep0Next"
              type="submit"
              class="btn btn-secondary-purple float-right"
              @click="submitCompanyInfo"
              data-test="onboarding-step-3-next"
            >
              Proceed to Policy
              <i class="fas fa-chevron-right text-white" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CompanyInfo from './_CompanyInfo.vue'

export default {
  name: 'Step3BusinessDetails',
  props: {
    step: String,
    policyholder: Object,
    companyInfoData: Object
  },
  components: {
    CompanyInfo
  },
  data () {
    return {
      invalidCompany: false,
      invalidCompanyName: '',
      invalidCompanyReason: ''
    }
  },
  computed: {
    loadingBarClass () {
      if (this.policyholder.can_configure_insurance) return 'loading_bar w-75'
      else return 'loading_bar w-66'
    },
    invalidCompanyMessage () {
      if (this.invalidCompanyReason === 'NO_COMPANY_SET') {
        return 'The company name is not valid, please select one from the options'
      } else if (this.invalidCompanyReason === 'ALREADY_REGISTERED') {
        return `Your company ${this.invalidCompanyName} \
                already has an Anansi account. \
                Please log in using it to access you account. \
                If you're having problems please contact us at hello@withanansi.com`
      } else {
        return 'This company is not valid'
      }
    }
  },
  methods: {
    submitCompanyInfo () {
      if (this.$refs.companyInfo.isCompanyValid()) {
        if (document.getElementById('CompanyInfoForm').reportValidity()) {
          return this.$refs.companyInfo
            .setCompanyInformation()
            .then((response) => {
              this.$emit('getPolicyholderData')
              this.$emit('nextScreen')
            }).catch(e => {
              if (e.response.status === 409) {
                this.invalidCompany = true
                this.invalidCompanyReason = e.response.data.reason
                this.invalidCompanyName = this.$refs.companyInfo.selectedCompanyInformation.name
                this.$refs.welcomeModalStep3Body.scrollTop = 0
              } else {
                alert('Something went wrong, please try again later')
              }
            })
        }
      } else {
        this.invalidCompany = true
        this.invalidCompanyReason = 'NO_COMPANY_SET'
        this.$refs.welcomeModalStep3Body.scrollTop = 0
      }
    }
  }
}
</script>

<script setup>
import AccountCreatedLayout from '@/components/onboarding-v2/account-created/AccountCreatedLayout.vue'
import { usePolicyholderStore } from '@/stores/policyholder'
import ConfettiExplosion from 'vue-confetti-explosion'

const policyholderStore = usePolicyholderStore()
</script>

<template>
  <div>
    <AccountCreatedLayout :policyholder="policyholderStore.$state" />
    <div class="tw-w-2 tw-mx-auto">
      <ConfettiExplosion :duration="7000" :particleCount="200" :force="0.3" :stageWidth="1920" :stageHeight="800"/>
    </div>
  </div>
</template>

<template>
  <div class="container top-container">
    <div class="container h-100 my-5">
      <p class="mb-0 my-5 this-is-how-wrapper">
        This is how we operate:
      </p>
      <div class="row mb-4">
        <div class="col">
          <div class="onboarding-step-border-bubble mx-auto h-100">
            <div class="bubble-content">
              <p class="mb-0 onboarding-highlight-span">1. Coverage and Claims</p>
              <p class="mb-0 font-weight-bold">Coverage for {{ coverage_types }}.</p>
              <div v-if="coverageIncludesLoss">
                <br />
                <p class="mb-0 font-weight-bold">24/7 shipment tracking and automated claims*</p>
                <p class="mb-2 mb-small-font">
                  *Loss claims only.
                  Please note: if a parcel ships without a tracking number it will still
                  be insured but you will have to submit claims manually.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="onboarding-step-border-bubble mx-auto h-100">
            <div class="bubble-content">
              <p class="mb-0 onboarding-highlight-span">2. Pricing</p>
              <p class="mb-0 font-weight-bold">UK to UK: {{ domesticPremiumPercentage }}%*</p>
              <p class="mb-0 font-weight-bold">UK to International: {{ internationalPremiumPercentage }}%</p>
              <p class="mb-2">of the parcel or consignment retail value.</p>
              <p
                class="mb-2 font-weight-light font-italic mb-small-font"
              >
                E.g. If the parcel retail value is £{{ exampleShipmentPrice }}, you pay £{{ domesticExample }} (UK to UK) or £{{ internationalExample }} (UK to International) to insure it.
              </p>
              <p class="mb-2 mb-small-font">
                *Excluding Insurance Premium Tax (IPT)
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <div class="onboarding-step-border-bubble mx-auto h-100">
            <div class="bubble-content">
              <p class="mb-0 onboarding-highlight-span">
                3. Policy Information
              </p>
              <p class="mb-0 font-weight-bold">No excess.</p>
              <p class="mb-0 font-weight-bold">Full retail value payouts.</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="onboarding-step-border-bubble mx-auto h-100">
            <div class="bubble-content">
              <p class="mb-0 onboarding-highlight-span">
                4. Premium Payments
              </p>
              <p class="mb-0 font-weight-bold">Monthly invoices via email.</p>
              <p class="mb-0 font-weight-bold">Make payment directly in the app.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ContractDataService from '@/services/ContractDataService.js'
import { roundWithDecimalPlaces, capitalizeString } from '@/utils/format'
export default {
  name: 'PolicyBenefits',

  data () {
    return {
      coverageIncludesLoss: false,
      coverage_types: '',
      domesticPremiumPercentage: 0.0,
      internationalPremiumPercentage: 0.0,
      exampleShipmentPrice: 100,
      domesticExample: 0.0,
      internationalExample: 0.0
    }
  },

  beforeMount () {
    this.getAndProcessContractData()
  },

  methods: {
    getAndProcessContractData () {
      ContractDataService.getContractDetails().then(response => {
        if (response.data) {
          const data = response.data
          this.coverageIncludesLoss = data.coverage_types.includes('LOSS')
          this.coverage_types = data.coverage_types.map(capitalizeString).join(', ')
          this.domesticPremiumPercentage = roundWithDecimalPlaces(data.domestic_premium * 100, 2)
          this.internationalPremiumPercentage = roundWithDecimalPlaces(data.international_premium * 100, 2)
          this.domesticExample = roundWithDecimalPlaces(this.exampleShipmentPrice * data.domestic_premium, 2)
          this.internationalExample = roundWithDecimalPlaces(this.exampleShipmentPrice * data.international_premium, 2)
        }
      })
    }
  }
}
</script>

<style>
.top-container {
  max-width: none !important
}

.this-is-how-wrapper {
  margin-top: 40px !important;
  font-size: 1.3125rem;
}

.mb-small-font {
  font-size: small;
}
</style>

<script setup>
import { useMainStore } from '@/stores'

const store = useMainStore()

function handleGoToDashboardClick () {
  store.setCompletedOnboarding()
}
</script>

<template>
  <div>
    <button @click="handleGoToDashboardClick">Go to my dashboard</button>
  </div>
</template>

<style scoped>
button {
  background-color: #B18D3F;
  color: white;
  padding: 5px 10px;
  border-radius: .25rem;
  border: none;
}
</style>

<template>
  <NavigationBar v-bind="{ policy: true }" />
  <div v-if="policyholder" class="container-fluid pt-7 flex-fill">
    <!-- Page Content  -->
    <div id="content" class="col">
      <div class="">
        <div class="col-12 card shadow-light p-5" data-test="policy-page-title">
          <h1>Policy Schedule</h1>
          <p>
              This schedule outlines what's insured. It should be read carefully
              with your
              <a :href="wording_link"
                  target="_blank"
                  data-test="terms-and-conditions">
                  Policy Terms and Conditions
              </a>
              and
              <a
                :href="ipid_link"
                target="_blank"
                data-test="product-information-document">
                Insurance Product Information Document
              </a>
              .
            </p>
          <hr />
          <PolicySchedule />
        </div>

        <!--        CONFIRMATION -->
        <div class="col-12 card shadow-light">
          <form id="confirmationForm" class="form-group needs-validation" :key="policyholder">
            <template v-if="!policyholder.hasCompanyData">
              <CompanyInfo @savedCompanyInfo="this.getPolicyholderData"/>
            </template>

            <template v-else>
              <!-- When there's company data -->
              <h4>Company Information</h4>

              <div id="confirmationUserInputDetails" class="mt-4">
                  <div
                  class="
                    form-group
                    text-left
                    col-12
                    p-0
                    d-flex
                    justify-content-between
                  "
                >
                  <div class="form-group text-left col-5 p-0">
                    <label for="confirmationAddress1">First Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="first_name"
                      v-model="policyholder.first_name"
                      disabled=""
                      required=""
                    />
                  </div>
                  <div class="form-group text-left col-6 p-0">
                    <label for="confirmationAddress2">Last Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="flast_name"
                      v-model="policyholder.last_name"
                      disabled=""
                      required=""
                    />
                  </div>
                </div>

                <div class="form-group">
                  <label for="confirmationBusiness">Business Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="confirmationBusiness"
                    disabled=""
                    v-model="policyholder.company.company_name"
                    required=""
                  />
                </div>

                <div
                  class="
                    form-group
                    text-left
                    col-12
                    p-0
                    d-flex
                    justify-content-between
                  "
                >
                  <div class="form-group text-left col-5 p-0">
                    <label for="confirmationAddress1">Address line 1</label>
                    <input
                      type="text"
                      class="form-control"
                      id="confirmationAddress1"
                      v-model="policyholder.company.address.address_line_1"
                      disabled=""
                      required=""
                    />
                  </div>
                  <div class="form-group text-left col-6 p-0">
                    <label for="confirmationAddress2">Address line 2</label>
                    <input
                      type="text"
                      class="form-control"
                      id="confirmationAddress2"
                      v-model="policyholder.company.address.address_line_2"
                      disabled=""
                      required=""
                    />
                  </div>
                </div>

                <div
                  class="
                    form-group
                    text-left
                    col-12
                    p-0
                    d-flex
                    justify-content-between
                  "
                >
                  <div class="form-group text-left col-4 p-0">
                    <label for="confirmationPostalCode">Post Code</label>
                    <input
                      type="text"
                      class="form-control"
                      id="confirmationPostalCode"
                      v-model="policyholder.company.address.postal_code"
                      disabled=""
                      required=""
                    />
                  </div>
                  <div class="form-group text-left col-4 p-0">
                    <label for="confirmationRegion">County</label>
                    <input
                      type="text"
                      class="form-control"
                      id="confirmationRegion"
                      v-model="policyholder.company.address.region"
                      disabled=""
                      required=""
                    />
                  </div>
                  <div class="form-group text-left col-3 p-0">
                    <label for="confirmationPremises">Building Number(s)</label>
                    <input
                      type="text"
                      class="form-control"
                      id="confirmationPremises"
                      v-model="policyholder.company.address.premises"
                      disabled=""
                      required=""
                    />
                  </div>
                </div>

                <div
                  class="
                    form-group
                    text-left
                    col-12
                    p-0
                    d-flex
                    justify-content-between
                  "
                >
                  <div class="form-group text-left col-5 p-0">
                    <label for="confirmationLocality">City</label>
                    <input
                      type="text"
                      class="form-control"
                      id="confirmationLocality"
                      v-model="policyholder.company.address.locality"
                      disabled=""
                      required=""
                    />
                  </div>
                  <div class="form-group text-left col-6 p-0">
                    <label for="confirmationCountry">Country</label>
                    <input
                      type="text"
                      class="form-control"
                      id="confirmationCountry"
                      v-model="policyholder.company.address.country"
                      disabled=""
                      required=""
                    />
                  </div>
                </div>

                <div class="form-group">
                  <input
                    type="hidden"
                    class="form-control"
                    id="confirmationNumber"
                    required=""
                    value="None"
                  />
                </div>

              </div>
              <hr />
            </template>
          </form>
        </div>
      </div>
    </div>
    <!-- <div>
      <span v-html="policyScheduleHTML"></span>
    </div> -->
  </div>
  <MainFooter />
</template>

<script>
// @ is an alias to /src
import NavigationBar from '@/components/NavigationBar.vue'
import MainFooter from '@/components/MainFooter.vue'
import PolicyholderDataService from '@/services/PolicyholderDataService.js'
import PolicySchedule from '@/components/PolicySchedule.vue'
import CompanyInfo from '@/components/onboarding/_CompanyInfo.vue'
import addressAsSingleString from '@/utils/addressAsSingleString.js'
import ContractDataService from '@/services/ContractDataService.js'

export default {
  name: 'PolicyView',
  title: 'Anansi - Policy',
  components: {
    NavigationBar,
    MainFooter,
    CompanyInfo,
    PolicySchedule
  },

  data () {
    return {
      policyholder: PolicyholderDataService.getEmptyPolicyholder(),
      single_line_address: undefined,
      wording_link: undefined,
      ipid_link: undefined
    }
  },

  beforeMount () {
    this.getPolicyholderData()
    this.getContractDocumentUrls()
  },

  methods: {
    getPolicyholderData () {
      return PolicyholderDataService.getPolicyholder().then((policyholderData) => {
        this.policyholder = policyholderData
        this.single_line_address = addressAsSingleString(policyholderData.company.address)
        this.$forceUpdate() // hack
      })
    },

    getContractDocumentUrls () {
      return ContractDataService.getContractDetails().then((contract) => {
        this.wording_link = contract.data.wording_url
        this.ipid_link = contract.data.ipid_url
      })
    }
  }
}
</script>

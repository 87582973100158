<script setup>
import AccountCreatedLayout from './account-created/AccountCreatedLayout.vue'

const props = defineProps(
  {
    policyholder: {
      type: Object
    }
  }
)

const { policyholder } = props

</script>

<template>
<div
  class="modal fade onBoarding show video-background"
  id="welcomeModalStep5"
  tabindex="-1"
  data-backdrop="static"
  data-keyboard="false"
  role="dialog"
  aria-labelledby="welcomeModalStep5Title"
  style="padding-right: 15px; display: block; z-index: 10000"
  aria-modal="true"
>
  <video autoplay loop muted>
    <source src="@/assets/confetti-video.webm" type="video/mp4">
    Your browser don't support html5 videos
  </video>
  <div
    id="modal-body"
    class="modal-dialog w-90 modal-dialog-centered"
    role="document"
  >
    <div class="modal-content h-100">
      <div class="modal-header">
        <h2 class="col-12 modal-title onboarding-modal-title text-center" id="welcomeModalStep0Title">
          Account Created!
        </h2>
      </div>
      <div class="loading_bar w-100">&nbsp;</div>
      <div class="modal-body px-0 py-0 h-100 container">
        <AccountCreatedLayout :policyholder="policyholder"/>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>
.video-background {
  overflow: hidden;
}

.video-background video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  opacity: 50%;
}
</style>

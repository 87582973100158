<template>
<div class="col-12 col-lg-10 mx-auto card shadow-lg mt-7 p-0">
  <div v-if="isInitialized" class="container mx-auto my-12">
    <div class="col-10 mx-auto">
      <div class="mx-9" id="shopify-login">
        <img class="d-block mx-auto mb-1 mt-3 mb-6" style="padding-bottom: 8px; height: 130px; margin-bottom: 20px;" src="@/assets/img/anansi/insurance_by_anansi.png" alt="off course">
        <form class="w-100 d-block mx-auto" id="login-with-shop-form" @submit.prevent="submit">
          <div class="container">
            <div v-if="statusCode === 200 || statusCode === 404">
                <h5 >Search for Claim information:</h5>
                <label class="mt-2" for="claim_id">Claim ID:</label>
                <input v-model="claim_id" class="form-control required" required id="claim_id" name="claim_id" placeholder="Claim ID"/>

                <button id="login-with-shop-submit-btn" class="btn btn-primary btn-email-signup-login w-100 mt-4" type="submit" :disabled="submitting">
                  <span v-if="submitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span v-if="submitting" class="sr-only">Loading...</span>
                  <span v-else>Search information</span>
                </button>
              <div v-if="success">
                <br>
                <p v-if="statusCode === 404" class="text-center mt-6 text-danger">
                  {{ error_text }}
                </p>
                <div v-else-if="claim_info">
                  <p>Claim ID: {{this.claim_info.id}}</p>
                  <p>Order Number: {{this.claim_info.order_number}}</p>
                  <p>Tracking Number: {{this.claim_info.tracking_number}}</p>
                  <p>Tracking Company Service: {{this.claim_info.tracking_company_service}}</p>
                  <p>Order created date: {{new Date(this.claim_info.fulfillment_created_date).toISOString().split('T')[0]}}</p>
                  <p>Order shipped date: {{this.claim_info.fulfillment_shipped_date}}</p>
                  <p>Carrier: {{this.claim_info.carrier_name}}</p>
                  <p>Status in our system: {{this.claim_info.status}}</p>
                  <p>Expected Delivery Date: {{this.claim_info.expected_delivery_date}}</p>
                  <p>Actual Delivered Date: {{this.claim_info.delivered_date}}</p>
                  <p>Shipping costs: £{{this.claim_info.shipping_costs}}</p>
                  <p>Total Price (fulfillment): £{{this.claim_info.fulfillment_total_price}}</p>
                  <p>Destination address:</p>
                  <div v-if="this.claim_info.hasOwnProperty('destination_address')">
                    <ul>
                      <li>Address 1: {{this.claim_info.destination_address.address_1}}</li>
                      <li>Address 2: {{this.claim_info.destination_address.address_2}}</li>
                      <li>Postal Code: {{this.claim_info.destination_address.postal_code}}</li>
                      <li>City: {{this.claim_info.destination_address.city}}</li>
                      <li>Country: {{this.claim_info.destination_address.country}}</li>
                    </ul>
                  </div>
                  <p>Insured items, quantities and values:</p>
                  <ul v-for="(item, index) in this.claim_info.items" v-bind:key="index">
                    <li>{{item.quantity}}x {{item.name}} - £{{item.price}} (Unit Price) <b v-if="item.claimed_item_quantity > 0">({{item.claimed_item_quantity}}x claimed)</b></li>
                  </ul>
                </div>
              </div>
            </div>

            <div v-else-if="statusCode === 401">
              Token has expired. Please, request a new token to the Engineering Team.
            </div>

            <div v-if="loading">
              <br>
              <div class="d-flex justify-content-center">
                <div class="spinner-border text-purple-anansi" role="loading status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <br>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import ApiDataService from '@/services/ApiDataService.js'

export default {
  name: 'ToDeleteAdmin',
  data () {
    return {
      statusCode: 200,
      claim_id: '',
      claim_info: undefined,
      error_text: undefined,
      submitting: false,
      propagateError: false,
      success: false,
      isInitialized: false,
      loading: true
    }
  },
  // eslint-disable-next-line
  errorCaptured (err, vm, info) {
    return this.propagateError
  },
  methods: {
    async submit () {
      this.propagateError = false
      this.submitting = true
      this.claim_info = undefined
      this.success = true

      ApiDataService.toDeleteAdminGetClaimInfo(this.$route.params.token, this.claim_id).then(response => {
        this.statusCode = response.status
        this.claim_info = response.data
        this.success = true
      }).catch(e => {
        this.statusCode = e.response.status
        if (this.statusCode === 404) {
          this.error_text = e.response.data.error_message
        }
        if (this.statusCode === 401) this.isInitialized = true
        if (this.statusCode === 403) this.$router.push({ path: '/signup/' })
        if (this.statusCode === 500) this.propagateError = true
      })
      this.submitting = false
    },

    checkTokenStatus () {
      this.propagateError = false
      ApiDataService.toDeleteAdminGetTokenStatus(this.$route.params.token).then(response => {
        this.statusCode = response.status
        if (this.statusCode === 200) this.isInitialized = true
      }).catch(e => {
        this.statusCode = e.response.status
        if (this.statusCode === 401) this.isInitialized = true
        if (this.statusCode === 403) this.$router.push({ path: '/signup/' })
        if (this.statusCode === 500) this.propagateError = true
      })
      this.loading = false
    }
  },
  beforeMount () {
    this.checkTokenStatus()
  }
}
</script>

<script setup>

</script>

<template>
  <div class="with-lateral-divider">
    <h2>You're all set! 🎉</h2>
    <p class="main-copy">Your account has been successfully created and you can start using Anansi!</p>
  </div>
</template>

<style scoped>
.with-lateral-divider {
  position: relative;
  margin-bottom: 40px;
}

.with-lateral-divider::before {
  position: absolute;
  content: '';
  top: 0;
  left: -10px;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 3px;
  background: purple;
}

.with-lateral-divider h2 {
  font-size: 24px;
}

.main-copy {
  font-size: 14px;
}
</style>

<script setup>
import EmailVerification from './EmailVerification.vue'

const props = defineProps(
  {
    policyholder: {
      type: Object
    }
  }
)

const { policyholder } = props
</script>

<template>
  <EmailVerification />
  <span v-if="!policyholder.is_partner_merchant">
    <p class="mb-0">
      You can start exploring Anansi while we verify your account with your affiliated partner. Don't worry - this process usually takes less than a day.
    </p>
    <p class="mb-0 my-5">
      <span class="onboarding-highlight-span">
        We'll let you know when its done and send you an email with your policy schedule,
      </span>
      so keep an eye on your inbox!
    </p>
  </span>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue'
import { Slide } from 'vue3-burger-menu'
import ManualShipmentService from '@/services/ManualShipmentService.js'

const canUploadShipments = ref(null)
const isLoading = ref(true)
const shipmentUploadFormat = ref(undefined)

onBeforeMount(async () => {
  await ManualShipmentService.getSettings()
    .then(async (response) => {
      canUploadShipments.value = response.data.can_upload
      shipmentUploadFormat.value = response.data.file_format

      if (shipmentUploadFormat.value) shipmentUploadFormat.value = shipmentUploadFormat.value.toUpperCase()
    }).catch(error => {
      console.log('Failed to get shipment file upload settings: ' + error)
      canUploadShipments.value = false
      shipmentUploadFormat.value = undefined
    })

  isLoading.value = false
})

</script>

<template>
  <section class="navbar navbar-expand-lg tw-bg-white border-bottom">
    <nav class="tw-flex tw-justify-between tw-px-8 tw-w-full">
      <router-link to="/" lass="navbar-brand">
        <img src="@/assets/img/anansi/logo_anansi.png" class="navbar-brand-img" alt="...">
      </router-link>
      <div class="tw-hidden lg:tw-flex tw-flex-end tw-items-center tw-pr-5" v-if="!isLoading">
        <router-link to="/" data-test="navbar-dashboard">
          Dashboard
        </router-link>
        <router-link to="/claims" data-test="navbar-claims">
          Claims
        </router-link>
        <router-link to="/invoices"  data-test="navbar-invoices">
          Invoices
        </router-link>
        <router-link to="/uploads" v-if="canUploadShipments" data-test="navbar-uploads">
          Uploads
        </router-link>
        <router-link to="/account" data-test="navbar-account">
          My Account
        </router-link>
        <router-link to="/logout" data-test="navbar-logout" class="tw-ml-6 tw-border tw-border-anansi-dark-gold tw-px-2 tw-py-1 tw-rounded tw-text-sm tw-text-black hover:tw-shadow-md hover:tw-text-black hover:tw-no-underline hover:bg-anansi-gold tw-duration-300 tw-ease-in-out">
          Sign Out
        </router-link>
      </div>
    </nav>
    <nav class="navbar-toggler" v-if="!isLoading">
      <Slide class="tw-flex tw-ml-auto tw-items-center tw-pr-8">
        <router-link to="/" data-test="navbar-dashboard-collapsed">
          Dashboard
        </router-link>
        <router-link to="/invoices" data-test="navbar-invoices-collapsed">
          Invoice
        </router-link>
        <router-link to="/claims" data-test="navbar-claims-collapsed">
          Claims
        </router-link>
        <router-link to="/account" data-test="navbar-account-collapsed">
          My Account
        </router-link>
        <router-link to="/uploads" v-if="canUploadShipments" data-test="navbar-uploads-collapsed">
          Uploads
        </router-link>
        <router-link to="/logout" class="tw-border tw-w-40 tw-mt-8 tw-border-anansi-dark-gold tw-px-2 tw-py-1 tw-rounded tw-flex tw-justify-center hover:tw-text-anansi-dark-gold hover:tw-no-underline">
          Sign Out
        </router-link>
      </Slide>
    </nav>
  </section>
</template>

<style>
nav a {
  @apply tw-text-black lg:tw-text-sm lg:tw-ml-2 tw-my-2 lg:tw-my-0 hover:tw-text-black hover:tw-no-underline hover:tw-font-bold tw-duration-300 tw-ease-in-out;
}
nav a.router-link-active.router-link-exact-active {
  @apply tw-text-anansi-dark-gold tw-font-bold;
}
.bm-burger-button {
  @apply tw--translate-x-4 tw-translate-y-4;
}
</style>

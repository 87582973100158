import { defineStore } from 'pinia'
import { usePolicyholderStore } from '@/stores/policyholder'
import OnBoardingDataService from '@/services/OnBoardingDataService.js'
import OnboardingStep1 from '@/components/onboarding-v2/steps/OnboardingStep1.vue'
import OnboardingStep2 from '@/components/onboarding-v2/steps/OnboardingStep2.vue'
import OnboardingStep3 from '@/components/onboarding-v2/steps/OnboardingStep3.vue'
import OnboardingCompleted from '@/components/onboarding-v2/steps/OnboardingCompleted.vue'
import FooterProvideBusinessDetails from '@/components/onboarding-v2/main-panel/dynamic-footers/FooterProvideBusinessDetails.vue'
import FooterReviewPolicy from '@/components/onboarding-v2/main-panel/dynamic-footers/FooterReviewPolicy.vue'

const STEPS_INFO = [
  {
    title: 'Insurance & Pricing details',
    stepComponent: OnboardingStep1,
    footerComponent: undefined,
    showError: false,
    validations: {
      insurance_values_in_range: true
    },
    nextStepFunction: async () => {
      const policyholderStore = usePolicyholderStore()
      const onboardingStore = useOnboardingStore()
      if (onboardingStore.isLoading) return
      if (!policyholderStore.can_configure_insurance) return

      onboardingStore.isLoading = true
      await OnBoardingDataService.setOrderValueRange(policyholderStore.min_insurance_value, policyholderStore.max_insurance_value)
      onboardingStore.isLoading = false
    }
  },
  {
    title: 'Provide your business details',
    stepComponent: OnboardingStep2,
    footerComponent: FooterProvideBusinessDetails,
    showError: false,
    validations: {
      first_name: false,
      last_name: false,
      company_info: false,
      company_country: false,
      uk_registered_company: false,
      is_valid_information: false
    },
    nextStepFunction: async () => {
      const policyholderStore = usePolicyholderStore()
      const onboardingStore = useOnboardingStore()
      if (onboardingStore.isLoading) return

      onboardingStore.isLoading = true
      await OnBoardingDataService.updatePolicyholderName(policyholderStore.first_name, policyholderStore.last_name)
      await OnBoardingDataService.setCompanyInformation(
        policyholderStore.company.company_name, // Public Name
        policyholderStore.company.company_name,
        undefined, // owner
        undefined, // first_name
        undefined, // last_name
        undefined, // currency
        policyholderStore.company.address.address_line_1,
        policyholderStore.company.address.address_line_2,
        policyholderStore.company.address.postal_code,
        policyholderStore.company.address.country,
        policyholderStore.company.address.locality,
        policyholderStore.company.address.premises,
        policyholderStore.company.address.region,
        policyholderStore.company.company_number,
        policyholderStore.uk_registered_company
      )
      onboardingStore.isLoading = false
    }

  },
  {
    title: 'Review and confirm your policy',
    stepComponent: OnboardingStep3,
    footerComponent: FooterReviewPolicy,
    showError: false,
    validations: {}
  }
]

export const useOnboardingStore = defineStore('useOnboardingStore', {
  state: () => ({
    stepsInfo: STEPS_INFO,
    currentStepRender: STEPS_INFO[0].stepComponent,
    currentFooterRender: STEPS_INFO[0].footerComponent,
    currentStep: STEPS_INFO[0],
    currentStepIndex: 1,
    isOnboardingCompleted: false,
    isLoading: false
  }),
  actions: {
    renderCurrentStep () {
      if (this.isOnboardingCompleted) {
        this.currentStepRender = OnboardingCompleted
        this.currentFooterRender = null
        this.currentStep = {}
      } else {
        this.currentStep = this.stepsInfo[this.currentStepIndex - 1]
        this.currentStepRender = this.currentStep.stepComponent
        this.currentFooterRender = this.currentStep.footerComponent
      }
    },
    previousStep () {
      this.currentStep.showError = false
      if (this.currentStepIndex - 1 > 0) {
        this.currentStepIndex -= 1
        this.renderCurrentStep()
      }
    },
    switchValidation (key, value) {
      if (this.currentStep.showError) this.currentStep.showError = false
      this.currentStep.validations[key] = value
    },
    checkValidations () {
      this.currentStep.showError = !Object.values(this.currentStep.validations).every(Boolean)
    },
    nextStep () {
      if (this.currentStep.showError) return

      if (this.currentStepIndex !== this.stepsInfo.length) {
        this.currentStepIndex += 1
        this.renderCurrentStep()
      }
    },
    completeOnboarding () {
      if (this.isLoading) return

      this.isLoading = true
      return OnBoardingDataService.setConfirmedTermsAndCompleteOnboarding().then(() => {
        this.isOnboardingCompleted = true
        this.renderCurrentStep()
      }).finally(() => {
        this.isLoading = false
      })
    },
    resetOnboardingStoreData () {
      this.isOnboardingCompleted = false
      this.currentStep = this.stepsInfo[0]
      this.currentStepRender = this.currentStep.stepComponent
      this.currentFooterRender = this.currentStep.footerComponent
      this.currentStepIndex = 1
    }
  }
})

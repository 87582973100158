<script setup>
import { computed, watch, ref } from 'vue'
import Slider from '@vueform/slider'
import { MIN_MAX_VALUE_INSURANCE_RANGE, MAX_VALUE_INSURANCE_RANGE, INSURANCE_RANGE_STEP } from '@/utils/variables'

const emit = defineEmits(['changedValues'])
const props = defineProps({
  range_lower_limit: {
    type: Number,
    default: 0
  },
  range_max_limit: {
    type: Number,
    default: MAX_VALUE_INSURANCE_RANGE
  },
  min_slider_value: {
    type: Number,
    required: true
  },
  max_slider_value: {
    type: Number,
    required: true
  },
  step: {
    type: Number,
    default: INSURANCE_RANGE_STEP
  },
  max_min_value: {
    type: Number,
    default: MIN_MAX_VALUE_INSURANCE_RANGE
  },
  format: {
    default: {
      prefix: '£ ',
      decimals: 0,
      thousand: ','
    }
  }
})

const limitLowerRangeAndMaxRange = (values) => {
  if (props.max_min_value && values[0] > props.max_min_value) values[0] = props.max_min_value
  return emit('changedValues', values[0], values[1])
}
const value = computed(() => {
  return [props.min_slider_value, props.max_slider_value]
})

const editableValue = ref([])
editableValue.value = [props.min_slider_value, props.max_slider_value]

watch(value, async (newVals, oldVals) => {
  if (newVals[0] === oldVals[0] && newVals[1] === oldVals[1]) return
  editableValue.value = value.value
})
</script>

<template>
  <Slider
    class="w-95 mt-8 mx-auto"
    v-model="editableValue"
    :min="range_lower_limit"
    :max="range_max_limit"
    :step="step"
    :format="format"
    @change="limitLowerRangeAndMaxRange"
  />
</template>

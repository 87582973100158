<template>
  <div
    class="modal fade onBoarding show"
    id="welcomeModalStep2"
    tabindex="-1"
    data-backdrop="static"
    data-keyboard="false"
    role="dialog"
    aria-labelledby="welcomeModalStep2Title"
    style="padding-right: 15px; display: block; z-index: 10000"
    aria-modal="true"
  >
    <div
      id="modal-body"
      class="modal-dialog w-90 modal-dialog-centered"
      role="document"
    >
      <div class="modal-content h-100">
        <div class="modal-header">
          <h2
            class="col-12 modal-title onboarding-modal-title text-center"
            id="welcomeModalStep0Title"
            data-test="onboarding-step-4-title"
          >
            STEP {{ step }}: Review and confirm your Policy Schedule
          </h2>
        </div>
        <div class="loading_bar w-100">&nbsp;</div>
        <div class="modal-body px-0 py-0 h-100">
          <div class="container" style="max-width: none !important">
            <div class="row justify-center">
              <div class="container h-100">
                <div
                  class="
                    justify-content-center
                    align-self-center
                    my-5
                    onboarding-policy-schedule-box
                  "
                >
                  <div class="onboarding-policy-schedule-box-content">
                    <h2 class="onboarding-highlight-span">
                      This is your Policy Schedule:
                    </h2>
                    <PolicySchedule/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer p-4">
          <div class="col-6 w-100 text-left text-muted mt-2">
            <p class="">
              <span class="font-weight-bolder text-black">Please Note:</span> By
              clicking
              <span class="font-weight-bolder">Complete</span>
              you are agreeing that you have read and understood the terms of
              the
              <a
                :href="wording_link"
                target="_blank"
              >
                Policy Terms and Conditions
              </a>
              and the
              <a
                :href="ipid_link"
                target="_blank"
              >
                Insurance Product Information Document
              </a>
            </p>
          </div>
          <button
            id="welcomeModalStep2Back"
            @click="$emit('prevScreen')"
            class="btn btn-link float-right"
            data-test="onboarding-step-4-back"
          >
            Go Back
            <i class="fas fa-chevron-right text-white" aria-hidden="true"></i>
          </button>
          <div class="btn-group">
            <button
              id="welcomeModalStep0Next"
              :disabled="is_loading"
              @click="setConfirmedTermsAndCompleteOnboarding()"
              type="submit"
              class="btn btn-secondary-purple float-right"
              data-test="onboarding-step-4-complete"
            >
              {{ is_loading ? 'Completing...' : 'Complete' }}
              <i class="fas fa-chevron-right text-white" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import PolicySchedule from '@/components/PolicySchedule.vue'
import OnBoardingDataService from '@/services/OnBoardingDataService.js'
import ContractDataService from '@/services/ContractDataService.js'

export default {
  name: 'Step4Confirmation',
  props: {
    step: String
  },
  components: {
    PolicySchedule
  },
  data () {
    return {
      is_loading: false,
      wording_link: undefined,
      ipid_link: undefined
    }
  },

  beforeMount () {
    this.getContractDocumentUrls()
  },

  methods: {
    setConfirmedTermsAndCompleteOnboarding () {
      this.is_loading = true
      return OnBoardingDataService.setConfirmedTermsAndCompleteOnboarding().then((response) => {
        this.is_loading = false
        this.$emit('nextScreen')
      })
    },

    getContractDocumentUrls () {
      return ContractDataService.getContractDetails().then((contract) => {
        this.wording_link = contract.data.wording_url
        this.ipid_link = contract.data.ipid_url
      })
    }
  }
}
</script>

<template>
  <div
    class="modal fade onBoarding show"
    id="welcomeModalStep01"
    tabindex="-1"
    data-backdrop="static"
    data-keyboard="false"
    role="dialog"
    aria-labelledby="welcomeModalStep01Title"
    style="padding-right: 15px; display: block; z-index: 10000"
    aria-modal="true"
  >
    <div
      id="modal-body"
      class="modal-dialog w-90 modal-dialog-centered"
      role="document"
    >
      <div class="modal-content h-100">
        <div class="modal-header">
          <h2
            class="col-12 modal-title onboarding-modal-title text-center"
            id="welcomeModalStep01Title"
            data-test="onboarding-step-1-title"
          >
            STEP {{ step }}: Review your policy benefits
          </h2>
        </div>
        <div :class="loadingBarClass">&nbsp;</div>
        <div
          class="modal-body px-0 py-0 h-100"
          style="height: 75vh !important; display: flex; align-items: center"
        >
          <PolicyBenefits />
        </div>
        <div class="modal-footer p-4">
          <button
            id="welcomeModalStep2Back"
            @click="$emit('prevScreen')"
            class="btn btn-link float-right"
            data-test="onboarding-step-1-back"
          >
            Go Back
            <i class="fas fa-chevron-right text-white" aria-hidden="true"></i>
          </button>
          <div class="btn-group">
            <button
              id="welcomeModalStep0Next"
              @click="$emit('nextScreen')"
              type="submit"
              class="btn btn-secondary-purple float-right"
              data-test="onboarding-step-1-next"
            >
              Next
              <i class="fas fa-chevron-right text-white" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PolicyBenefits from '@/components/onboarding/PolicyBenefits.vue'

export default {
  name: 'Step1ReviewPolicy',
  props: {
    step: String,
    policyholder: Object
  },
  components: {
    PolicyBenefits
  },
  computed: {
    loadingBarClass () {
      if (this.policyholder.can_configure_insurance) return 'loading_bar w-25'
      else return 'loading_bar w-33'
    }
  }

}
</script>

<template>
  <div
    class="modal fade onBoarding show"
    id="welcomeModalStep0"
    tabindex="-1"
    data-backdrop="static"
    data-keyboard="false"
    role="dialog"
    aria-labelledby="welcomeModalStep0Title"
    style="padding-right: 15px; display: block; z-index: 10000"
    aria-modal="true"
    data-test="onboarding-modal"
  >
    <div
      id="modal-body"
      class="modal-dialog w-90 modal-dialog-centered"
      role="document"
    >
      <div class="modal-content h-100">
        <div class="modal-header">
          <h2
            class="col-12 modal-title onboarding-modal-title text-center"
            id="welcomeModalStep0Title"
          >
            Welcome to Anansi!
          </h2>
        </div>
        <div class="loading_bar w-0">&nbsp;</div>
        <div
          class="modal-body px-0 py-0 h-100"
          style="height: 75vh !important; display: flex; align-items: center"
        >
          <div class="container" style="max-width: none !important">
            <div class="container h-100 my-5 text-center">
              <p class="mb-0 my-5" style="margin-bottom: 20px; font-size: 1.3125rem">
                Let's get you started by setting up your account in {{ policyholder.can_configure_insurance ? 'four' : 'three' }} simple
                steps:
              </p>
              <p class="mb-0 onboarding-step-border-box mx-auto">
                <span class="onboarding-highlight-span">Step 1:</span>
                Review your policy benefits
              </p>
              <p v-if="policyholder.can_configure_insurance" class="mb-0 onboarding-step-border-box mx-auto">
                <span class="onboarding-highlight-span">Step 2:</span>
                Select insurance coverage retail values
              </p>
              <p class="mb-0 onboarding-step-border-box mx-auto">
                <span class="onboarding-highlight-span">{{ policyholder.can_configure_insurance ? 'Step 3' : 'Step 2'}}:</span>
                Provide your business details
              </p>
              <p class="mb-0 onboarding-step-border-box mx-auto">
                <span class="onboarding-highlight-span">{{ policyholder.can_configure_insurance ? 'Step 4' : 'Step 3' }}:</span>
                Review and confirm your policy schedule.
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer p-4">
          <div class="btn-group">
            <button
              id="welcomeModalStep0Next"
              @click="$emit('nextScreen')"
              type="submit"
              class="btn btn-secondary-purple float-right"
              data-test="onboarding-start-button"
            >
              Start
              <i class="fas fa-chevron-right text-white" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Step0Welcome',
  props: {
    policyholder: Object
  }
}
</script>

<template>
  <div
    v-if="showModal <= 5"
    class="modal-backdrop fade show"
    style="z-index: 5000"
  ></div>

  <Step0Welcome v-if="showModal == 0" :policyholder="policyholder" @nextScreen="nextScreen"/>
  <Step1ReviewPolicy v-if="showModal == 1" step="1" :policyholder="policyholder" @prevScreen="prevScreen" @nextScreen="nextScreen"/>
  <Step2InsuranceRange
    v-if="showModal == 2 && policyholder.can_configure_insurance"
    step="2"
    :min_insurance_value_prop="min_insurance_value"
    :max_insurance_value_prop="max_insurance_value"
    @onChangeInsuranceValues="onChangeInsuranceValues"
    @getPolicyholderData="getPolicyholderData"
    @prevScreen="prevScreen"
    @nextScreen="nextScreen" />

  <Step3BusinessDetails
    v-if="showModal == 3"
    :step="policyholder.can_configure_insurance ? '3' : '2'"
    :policyholder="policyholder"
    :companyInfoData="companyInfoData"
    @onChangeCompanyData="onChangeCompanyData"
    @getPolicyholderData="getPolicyholderData"
    @prevScreen="prevScreen"
    @nextScreen="nextScreen" />

  <Step4Confirmation v-if="showModal == 4" :step="policyholder.can_configure_insurance ? '4' : '3'" @prevScreen="prevScreen" @nextScreen="nextScreen"/>
  <Step5AccountCreated v-if="showModal == 5" :policyholder="policyholder" @nextScreen="nextScreen"/>

</template>

<script>
import PolicyholderDataService from '@/services/PolicyholderDataService.js'
import addressAsSingleString from '@/utils/addressAsSingleString.js'
import Step0Welcome from '@/components/onboarding/Step0Welcome.vue'
import Step1ReviewPolicy from '@/components/onboarding/Step1ReviewPolicy.vue'
import Step2InsuranceRange from '@/components/onboarding/Step2InsuranceRange.vue'
import Step3BusinessDetails from '@/components/onboarding/Step3BusinessDetails.vue'
import Step4Confirmation from '@/components/onboarding/Step4Confirmation.vue'
import Step5AccountCreated from '@/components/onboarding/Step5AccountCreated.vue'

export default {
  name: 'OnBoarding',
  props: {},
  components: {
    Step0Welcome,
    Step1ReviewPolicy,
    Step2InsuranceRange,
    Step3BusinessDetails,
    Step4Confirmation,
    Step5AccountCreated
  },
  async beforeMount () {
    const helperDate = new Date()
    const year = helperDate.getFullYear()
    const month = helperDate.getMonth() + 1
    const day = helperDate.getDate()
    this.currentDate = day + '/' + month + '/' + year
    this.endDate = day + '/' + month + '/' + (year + 1)

    await this.getPolicyholderData()
  },
  computed: {
    lossAndDelayMessage () {
      let message = '*Loss claims only.'
      message += ' Please note: if a parcel ships without a tracking number it will still '
      message += 'be insured but you will have to submit claims manually.'
      return message
    }
  },
  methods: {
    onChangeCompanyData (companyInfoData) {
      this.companyInfoData = companyInfoData
    },
    onChangeInsuranceValues (min, max) {
      this.min_insurance_value = min
      this.max_insurance_value = max
    },
    nextScreen (steps = 1) {
      if (this.showModal === 1 && !this.policyholder.can_configure_insurance) steps = 2
      this.showModal += steps
    },

    prevScreen (steps = 1) {
      if (this.showModal === 3 && !this.policyholder.can_configure_insurance) steps = 2
      this.showModal -= steps
    },

    getPolicyholderData () {
      return PolicyholderDataService.getPolicyholder().then(
        (policyholderData) => {
          if (policyholderData === undefined) {
            this.$router.push('LogOut')
          }
          this.policyholder = policyholderData
          this.single_line_address = addressAsSingleString(
            policyholderData.company.address
          )
          // this.$forceUpdate() // hack
        }
      )
    }
  },

  data () {
    return {
      min_insurance_value: 0,
      max_insurance_value: 5000,
      companyInfoData: {
        publicName: undefined,
        name: undefined,
        ownerName: undefined,
        firstName: undefined,
        lastName: undefined,
        currency: undefined,
        address_1: undefined,
        address_2: undefined,
        postal_code: undefined,
        country: undefined,
        locality: undefined,
        premises: undefined,
        region: undefined,
        id: undefined,
        validInformation: undefined,
        policyholderUkCompany: true
      },
      selected: [],
      showModal: 0,
      policyholder: PolicyholderDataService.getEmptyPolicyholder(),
      single_line_address: undefined
    }
  }
}
</script>

<style src="@vueform/slider/themes/default.css"></style>

import { defineStore } from 'pinia'
import { SplitFactory } from '@splitsoftware/splitio'

export const useSplitioStore = defineStore('useSplitioStore', {
  state: () => ({
    featureFlagsDict: {
      SHOW_FAQS_IN_ONBOARDING: 'SHOW_FAQS_IN_ONBOARDING',
      NEW_ONBOARDING: 'NEW_ONBOARDING'
    },
    featureFlagStatus: {
      SHOW_FAQS_IN_ONBOARDING: undefined,
      NEW_ONBOARDING: undefined
    },
    client: undefined
  }),
  actions: {
    prepareClient (userId = undefined) {
      if (this.client) return this.client

      this.client = SplitFactory({
        core: {
          authorizationKey: process.env.VUE_APP_SPLITIO_API_KEY,
          key: userId || process.env.VUE_APP_SPLITIO_USER_KEY
        },
        features: { // this piece of code returns this values for this feature flags locally. authorizationKey needs to be localhost
          SHOW_FAQS_IN_ONBOARDING: { treatment: 'on' },
          NEW_ONBOARDING: { treatment: 'on' }
        }
      }).client()
      return this.client
    },
    async featureFlag (key, userId = undefined) {
      if (this.featureFlagStatus[key] !== undefined) return this.featureFlagStatus[key]

      return this.prepareClient(userId).ready().then(async () => {
        const isEnabled = await this.client.getTreatment(key) === 'on'
        this.featureFlagStatus[key] = isEnabled
        return this.featureFlagStatus[key]
      })
    }

  }
})

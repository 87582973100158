<script>
import { useSplitioStore } from '@/stores/splitio.js'
import { SOURCE_PARTNERS } from '@/utils/variables'
import AccountCreatedDespatchCloud from '@/components/onboarding/account-created/AccountCreatedDespatchCloud.vue'
import AccountCreatedMintsoft from '@/components/onboarding/account-created/AccountCreatedMintsoft.vue'
import FaqsUrlFooter from '@/components/onboarding/account-created/FaqsUrlFooter.vue'
import AccountCreatedWelcome from '@/components/onboarding/account-created/AccountCreatedWelcome.vue'
import GoToDashboardButton from '@/components/onboarding/account-created/GoToDashboardButton.vue'

export default {
  name: 'SignupEmail',
  props: {
    policyholder: {
      type: Object
    }
  },
  components: {
    AccountCreatedDespatchCloud,
    AccountCreatedMintsoft,
    FaqsUrlFooter,
    AccountCreatedWelcome,
    GoToDashboardButton
  },
  data () {
    return {
      showFAQs: false,
      source_partners: SOURCE_PARTNERS
    }
  },
  async mounted () {
    const splitioStore = useSplitioStore()
    await splitioStore.featureFlag(splitioStore.featureFlagsDict.SHOW_FAQS_IN_ONBOARDING)
      ? this.showFAQs = true
      : this.showFAQs = false
  }
}
</script>

<template>
  <div class="container h-100 my-5">
    <!-- Shopify users will have a generic welcome -->
    <AccountCreatedWelcome />
    <AccountCreatedDespatchCloud v-if="!policyholder.can_configure_insurance" :policyholder="policyholder" />
    <AccountCreatedMintsoft v-if="policyholder.source_partner==source_partners.MINTSOFT" :policyholder="policyholder" />
    <GoToDashboardButton />
    <FaqsUrlFooter v-if="showFAQs"/>
  </div>
</template>
